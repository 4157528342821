import React, { useEffect, useMemo, useRef } from 'react';
import { HelpCircle } from 'lucide-react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormField, Modal, Message, Popover, Button } from '@getro/rombo';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Flex, Box } from 'rebass/styled-components';
import {
  makeSelectIntroductionRequestError,
  makeSelectIntroductionRequestOrganization,
  makeSelectIntroductionRequestSent,
  makeSelectIntroductionRequestsLimit,
  makeSelectIntroductionRequestsRemaining,
} from '../../../redux/selectors/introductionRequest';
import STRINGS from '../../../constants/strings';
import {
  loadCreateIntroductionRequest,
  loadUpdateIntroductionsRemaining,
  loadUpdateLinkedInUrl,
} from '../../../redux/actions/introductionRequestActions';
import { makeSelectIsLoading } from '../../../redux/selectors/loading';
import { hasValidLinkedInUrlSelector, isAuthenticatedSelector } from '../../../redux/selectors/user';
import LINKEDIN_URL_REGEX from '../../../constants/linkedin';
import { usePrevious } from '../../../hooks/usePrevious';
import { PageLoader } from '../../atoms/pageLoader';
import { makeFeatureSelector } from '../../../redux/selectors/network';
import { NetworkSchema } from '../../../schema/network';

const RequestIntroductionModal = ({ network, isOpen, onCancel }) => {
  const formLinkedinUrlRef = useRef();
  const previousIsOpen = usePrevious(isOpen);
  const formRequestIntroduction = useRef();
  const hasIntroductionsLimit = makeFeatureSelector('introductions_limit', network);
  const introductionRequestsRemaining = useSelector(makeSelectIntroductionRequestsRemaining);
  const introductionRequestsLimit = useSelector(makeSelectIntroductionRequestsLimit);
  const updatingIntroductionsRemaining = useSelector(makeSelectIsLoading('updateIntroductionsRemaining'));
  const updatingLinkedInUrl = useSelector(makeSelectIsLoading('updateLinkedInUrl'));
  const hasValidLinkedInUrl = useSelector(hasValidLinkedInUrlSelector);
  const error = useSelector(makeSelectIntroductionRequestError);
  const introductionRequestSent = useSelector(makeSelectIntroductionRequestSent);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const dispatch = useDispatch();
  const organization = useSelector(makeSelectIntroductionRequestOrganization);
  const submitting = useSelector(makeSelectIsLoading('createIntroductionRequest'));
  const { name } = organization || {};

  useEffect(() => {
    if (hasIntroductionsLimit && isAuthenticated && isOpen && !previousIsOpen) {
      dispatch(loadUpdateIntroductionsRemaining({ network }));
    }
  }, [dispatch, hasIntroductionsLimit, isAuthenticated, isOpen, previousIsOpen, network]);

  const initialValues = useMemo(() => {
    if (hasValidLinkedInUrl) {
      return { coverLetter: '' };
    }

    return { url: '' };
  }, [hasValidLinkedInUrl]);

  const formSchema = useMemo(() => {
    if (hasValidLinkedInUrl) {
      return Yup.object().shape({
        coverLetter: Yup.string().required(STRINGS.validation.requiredField).max(140).nullable(),
      });
    }

    return Yup.object().shape({
      url: Yup.string()
        .matches(LINKEDIN_URL_REGEX, {
          message: STRINGS.validation.linkedinUrlError,
        })
        .nullable(),
    });
  }, [hasValidLinkedInUrl]);

  const title = useMemo(() => {
    if (introductionRequestSent) return 'Introduction requested';
    if ((!hasIntroductionsLimit || introductionRequestsRemaining > 0) && !introductionRequestSent) {
      return `Request Introduction to ${name}`;
    }
    if (
      hasIntroductionsLimit &&
      !updatingIntroductionsRemaining &&
      !introductionRequestSent &&
      introductionRequestsRemaining < 1
    ) {
      return 'You have 0 intro requests left today.';
    }

    return null;
  }, [
    hasIntroductionsLimit,
    introductionRequestSent,
    introductionRequestsRemaining,
    name,
    updatingIntroductionsRemaining,
  ]);

  const onSecureCancel = () => {
    if (!submitting) {
      onCancel();
    }
  };

  const onSubmitIntroductionRequest = ({ coverLetter }) => {
    dispatch(loadCreateIntroductionRequest({ network, coverLetter }));
  };

  const onSubmitUpdateLinkedinUrl = ({ url }) => {
    dispatch(loadUpdateLinkedInUrl({ url }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onSecureCancel}
      data-testid="request-introduction-modal"
      variant="default"
      title={title}
      actions={
        <Flex justifyContent={['flex-end']}>
          {introductionRequestSent && (
            <Button type="button" onClick={onSecureCancel} data-testid="dismiss-introduction-request-sent-button">
              Ok
            </Button>
          )}
          {(!hasIntroductionsLimit || introductionRequestsRemaining > 0) && !introductionRequestSent && (
            <>
              {hasValidLinkedInUrl && (
                <>
                  <Button
                    mr={2}
                    variant="secondary"
                    type="button"
                    onClick={onSecureCancel}
                    data-testid="cancel-request-introduction-button"
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={submitting}
                    type="button"
                    data-testid="request-introduction-button"
                    onClick={() => formRequestIntroduction.current?.handleSubmit()}
                  >
                    Request Introduction
                  </Button>
                </>
              )}
              {!hasValidLinkedInUrl && (
                <>
                  <Button
                    mr={2}
                    variant="secondary"
                    type="button"
                    onClick={onSecureCancel}
                    data-testid="cancel-linkedin-url-button"
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={updatingLinkedInUrl}
                    type="button"
                    data-testid="save-linkedin-url-button"
                    onClick={() => formLinkedinUrlRef.current?.handleSubmit()}
                  >
                    Save
                  </Button>
                </>
              )}
            </>
          )}
        </Flex>
      }
    >
      {updatingIntroductionsRemaining && <PageLoader />}
      {hasIntroductionsLimit &&
        !updatingIntroductionsRemaining &&
        !introductionRequestSent &&
        introductionRequestsRemaining < 1 && (
          <>
            <Text fontSize={2} mb={3} data-testid="no-intros-left-error">
              You have requested the maximum number of introductions ({introductionRequestsLimit}) today.
            </Text>
            <Text fontSize={2}>Come back tomorrow and request your introduction to {name}.</Text>
            <Text fontSize={2}>
              There is a limit because people&apos;s time is valuable. We encourage you to request only the most
              relevant intros and prioritize accordingly.
            </Text>
          </>
        )}
      {introductionRequestSent && (
        <Text mb={4} fontSize={2}>
          Hang tight! We have reached out to the {name} team and will make the introduction if there is a match.
        </Text>
      )}
      {(!hasIntroductionsLimit || introductionRequestsRemaining > 0) && !introductionRequestSent && (
        <>
          <Text mb={4} fontSize={2}>
            We will send an email to {name} sharing why you want to connect.
          </Text>

          {hasValidLinkedInUrl && (
            <Formik
              initialValues={initialValues}
              validationSchema={formSchema}
              onSubmit={onSubmitIntroductionRequest}
              innerRef={formRequestIntroduction}
            >
              {(form) => {
                const { values, setFieldValue } = form;
                const { coverLetter } = values;

                return (
                  <Form>
                    <FormField
                      field={{ name: 'coverLetter' }}
                      label="Add a 140 character note about why you are requesting this introduction:"
                      as="textarea"
                      value={coverLetter}
                      form={form}
                      onChange={(e) => setFieldValue('coverLetter', e.target.value)}
                    />
                    {error && (
                      <Message type="error" data-testid="request-introduction-error">
                        There was an error, please try again or contact us.
                      </Message>
                    )}
                    {hasIntroductionsLimit && introductionRequestsRemaining > 0 && (
                      <Box mt={2} mb={3} data-testid="introductions-remaining-info">
                        <Flex flexDirection="row">
                          <Text fontSize={2} fontWeight="medium" mr={2}>
                            {introductionRequestsRemaining}/{introductionRequestsLimit}
                          </Text>
                          <Text fontSize={2} mr={2}>
                            You have {introductionRequestsRemaining} more introduction
                            {introductionRequestsRemaining !== 1 ? 's' : ''} today.
                          </Text>
                          <Popover
                            trigger={<Box as={HelpCircle} strokeWidth="1.5" aria-hidden="true" />}
                            header="Introduction daily limit"
                            content={`Each day you have the option to request ${introductionRequestsLimit} introductions in this network. Make ‘em count!`}
                          />
                        </Flex>
                      </Box>
                    )}
                  </Form>
                );
              }}
            </Formik>
          )}
          {!hasValidLinkedInUrl && (
            <Formik
              initialValues={initialValues}
              validationSchema={formSchema}
              onSubmit={onSubmitUpdateLinkedinUrl}
              innerRef={formLinkedinUrlRef}
            >
              {() => (
                <Form data-testid="update-linkedin-url-form">
                  <Flex flexDirection="column">
                    <Text as="label" fontWeight="medium" mt={2} mb={3} fontSize={2}>
                      Please connect your linkedIn so we can showcase your experience.
                    </Text>
                    <Field
                      name="url"
                      type="text"
                      component={FormField}
                      placeholder="Insert your LinkedIn profile url"
                    />
                  </Flex>
                </Form>
              )}
            </Formik>
          )}
        </>
      )}
    </Modal>
  );
};

RequestIntroductionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  network: NetworkSchema.isRequired,
};

export default RequestIntroductionModal;
