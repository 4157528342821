import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Box, Text, Button } from 'rebass/styled-components';
import { Logo, Modal } from '@getro/rombo';
import NextLink from 'next/link';
import Container from '../../../atoms/Container';
import Footer from '../../../atoms/Footer';
import CustomFooter from '../../../molecules/customFooter';
import Hero from '../../../molecules/Hero';
import Navbar from '../../../organisms/Navbar';
import { toggleSignUpModal } from '../../../../redux/actions/userActions';
import { toggleIntroductionRequestModal } from '../../../../redux/actions/introductionRequestActions';
import { isAuthenticatedSelector, makeSelectShowSignUpModal } from '../../../../redux/selectors/user';
import useAllCompanies from '../../../../hooks/useAllCompanies';
import RequestIntroductionModal from '../../../organisms/requestIntroductionModal';
import { makeSelectIntroductionRequestModalVisible } from '../../../../redux/selectors/introductionRequest';
import { CustomHeader } from '../../../molecules/customHeader';
import { wlcGetroLogoClicked } from '../../../../redux/actions/layout';
import {
  hasAdvancedDesignSelector,
  hasCustomHeroSelector,
  networkLegalSelector,
  numbersInNavSelector,
  organizationsStringSelector,
  talentNetworkSelector,
} from '../../../../redux/selectors/network';
import { NetworkSchema } from '../../../../schema/network';
import { useTriggerScript } from '../../../atoms/Scripts/hooks/useTriggerScript';
import usePrivacyPolicyUrl from '../../../../hooks/usePrivacyPolicyUrl';

const PageLayout = ({ network, advancedDesign, children }) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const talentNetwork = talentNetworkSelector(network);
  const numbersInNav = numbersInNavSelector(network);
  const hasAdvancedDesign = hasAdvancedDesignSelector(network);
  const hasCustomHero = hasCustomHeroSelector(network);
  const networkLegal = networkLegalSelector(network);
  const showSignUpModal = useSelector(makeSelectShowSignUpModal);
  const introductionRequestModalVisible = useSelector(makeSelectIntroductionRequestModalVisible);

  const { list: allCompaniesList } = useAllCompanies({ network });
  const organizationsString = organizationsStringSelector(true, network);
  const organizationString = organizationsStringSelector(false, network);
  const onLogoClick = () => dispatch(wlcGetroLogoClicked({ network, origin: 'powered-by-footer' }));
  const { privacyPolicyUrl, isExternal: isExternalPrivacyPolicyUrl } = usePrivacyPolicyUrl(network);

  const dispatch = useDispatch();
  const { name: networkName, id } = network || {};

  useTriggerScript();

  if (!id) {
    return (
      <Flex backgroundColor="neutral.20" minHeight="100vh" flexDirection="column" alignItems="stretch" id="wlc-main">
        <Container id="content">{children}</Container>
      </Flex>
    );
  }

  return (
    <Flex
      bg="neutral.20"
      minHeight="100vh"
      flexDirection="column"
      alignItems="stretch"
      id="wlc-main"
      className="network-container"
    >
      {hasAdvancedDesign && <CustomHeader advancedDesign={advancedDesign} network={network} />}
      <Box width={1}>
        {!hasCustomHero && <Hero network={network} />}
        <div id="content">
          <Navbar
            organizationsString={organizationsString}
            network={network}
            isAuthenticated={isAuthenticated}
            talentNetwork={talentNetwork}
            talentNetworkV2={network.hasTalentNetworkV2}
            numbersInNav={numbersInNav}
          />
          <Container pb={5}>{children}</Container>
        </div>
      </Box>
      <Footer
        onLogoClick={onLogoClick}
        hasAdvancedDesign={hasAdvancedDesign}
        networkName={networkName}
        networkLegal={networkLegal}
        privacyPolicyUrl={privacyPolicyUrl}
        isExternalPrivacyPolicyUrl={isExternalPrivacyPolicyUrl}
      />
      {hasAdvancedDesign && <CustomFooter advancedDesign={advancedDesign} network={network} />}
      <RequestIntroductionModal
        network={network}
        isOpen={introductionRequestModalVisible}
        onCancel={() => dispatch(toggleIntroductionRequestModal())}
      />
      <Modal
        p={0}
        data-testid="signin-modal"
        onCancel={() => dispatch(toggleSignUpModal({ network }))}
        isOpen={showSignUpModal}
        variant="large"
      >
        <Flex alignItems="stretch" ml={-3}>
          <Box display={['none', 'block']} p={4} width={[1 / 2]} bg="neutral.50">
            <Flex flexDirection="column">
              <Text fontWeight="medium">
                Sign up to signal that you&rsquo;d be interested in working with a Fantasia {organizationString} to help
                us put the right opportunities at great {organizationsString} on your radar.
              </Text>
              <Text my={3}>How it works</Text>
              <Flex tx="lists" as="ol" flexDirection="column" variant="styleless">
                <Box as="li" mb={3}>
                  <Flex>
                    <Box mr={3}>
                      <Text variant="circle" aria-hidden="true">
                        1
                      </Text>
                    </Box>
                    <Flex ml={3} flexDirection="column">
                      <Text fontWeight="medium" mb={1}>
                        Tell us about your ideal fit
                      </Text>
                      <Text>
                        Fill out a 5-step profile designed to give us a sense of what type of opportunities you&rsquo;d
                        be open to next.
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
                <Box as="li" mb={3}>
                  <Flex>
                    <Box mr={3}>
                      <Text aria-hidden="true" variant="circle">
                        2
                      </Text>
                    </Box>
                    <Flex ml={3} flexDirection="column">
                      <Text fontWeight="medium" mb={1}>
                        Engage with our job board
                      </Text>
                      <Text>
                        Create lists of favorite {organizationsString} and job opportunities on our interactive job
                        board periodically to keep a pulse on priority opportunities.
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
                <Box as="li">
                  <Flex>
                    <Box mr={3}>
                      <Text aria-hidden="true" variant="circle">
                        3
                      </Text>
                    </Box>
                    <Flex ml={3} flexDirection="column">
                      <Text fontWeight="medium" mb={1}>
                        Get connected with hiring teams
                      </Text>
                      <Text>
                        Allow {organizationsString} in our network to search and discover your profile. If there is
                        mutual interest, skip-the-applicant-line, we&rsquo;ll make the introduction.
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          </Box>
          <Box p={4} width={[1, 1 / 2]}>
            <Flex height="100%" flexDirection="column" textAlign="center" alignItems="center">
              <Text mb={2} fontWeight="medium" as="h2" fontSize={4}>
                Join our talent network
              </Text>
              <NextLink shallow passHref href="/login" legacyBehavior>
                <Button color="customPrimary" mb={1} variant="anchorLink" as="a" className="theme_only">
                  Already a member? Log in here.
                </Button>
              </NextLink>
              <Flex alignItems="center" flexDirection="column" flex="1 1 auto" justifyContent="center">
                <Text fontWeight="medium" fontSize={3} my={3}>
                  {allCompaniesList.length.toLocaleString('en-US')} {organizationsString} use our network as a trusted
                  source to hire top talent.
                </Text>
                <Text mb={3}>Sign up to make yourself searchable to the network.</Text>
                <NextLink shallow passHref href="/onboarding" legacyBehavior>
                  <Button
                    as="a"
                    className="theme_only"
                    onClick={() => {
                      dispatch(toggleSignUpModal({ network }));
                    }}
                    fontSize={3}
                  >
                    Sign up
                  </Button>
                </NextLink>
              </Flex>
              <Box mt={[5, 0]} data-testid="powered-by" width={['120px']}>
                <Box as={Logo} showPoweredBy color={hasAdvancedDesign ? 'var(--color-brand-black-dark)' : undefined} />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Modal>
    </Flex>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  advancedDesign: PropTypes.object,
  network: NetworkSchema,
};

PageLayout.defaultProps = {
  advancedDesign: {},
  network: {},
};

export default PageLayout;
